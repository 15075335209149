export default {
	messages: {
		ru: {
			'Детальніше про перевірки авто': 'Подробнее о проверках авто',
			'запитуйте у менеджера проекту': 'спрашивайте у менеджера проекта',
			'Андрій': 'Андрей',
		},
		uk: {
			'Детальніше про перевірки авто': 'Детальніше про перевірки авто',
			'запитуйте у менеджера проекту': 'запитуйте у менеджера проекту',
			'Андрій': 'Андрій',
		},
	}
};

import { getAvatarById } from '../../../helpers/getAvatarById';
const { mapActions, mapGetters } = require('vuex');

export default {
	name: 'Slide',
	props: ['data', 'appendFlickityElement'],
	methods: {
		...mapActions({
			fetchAdv: 'shared/advertisement/fetch',
		}),
		getAvatarUrl(userId, size) {
			return getAvatarById(userId, size);
		},
		getAdvInfo(id) {
			if (Number(id)) {
				this.fetchAdv({id});
			}
		},
	},
	computed: {
		...mapGetters({
			advertisement_: 'shared/advertisement/personalization',
		}),
		advertisement() {
			return this.advertisement_(this.data.auto_id) || {};
		},
		advertisementTitle() {
			return Object.keys(this.advertisement).length ? `${this.advertisement.brandName} ${this.advertisement.modelName}` : '';
		},
		rating() {
			const recommend = this._result(this.data, 'recommed');
			// 2 Не рекомендую
			// 1 Рекомендую
			return Number(recommend) ? recommend < 2 ? 5 : 3 : null;
		},
	},
	mounted() {
		this.appendFlickityElement(this.$el);
		this.getAdvInfo(this.data.auto_id);
	},
};

export default {
	messages: {
		ru: {
			'відгуків': 'отзыв | отзыв | отзыва | отзывов',
			'Показати на карті': 'Показать на карте',
			'Показати': 'Показать',
		},
		uk: {
			'відгуків': 'відгук | відгук | відгуки | відгуків',
			'Показати на карті': 'Показати на карті',
			'Показати': 'Показати',
		},
	}
};

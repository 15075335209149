import isOnline from '../../../mixins/isOnline';

export default {
	name: 'AskManager',
	components: {
		'vue-call-order': require('../../../components/CheckCar/CallOrder/index.vue').default,
	},
	mixins: [isOnline],
	i18n: require('./i18n').default,
};

const { mapActions, mapGetters } = require('vuex');
const { getDistance } = require('../../helpers/getDistanceBetweenCoordinates');
import scrollToTop from '../../helpers/cabinetScroll';

export default {
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{name: 'description', content: this.description},
				{property: 'og:title', content: this.title},
				{property: 'og:description', content: this.description},
				{name: 'ROBOTS', content: 'INDEX, FOLLOW'}
			]
		};
	},
	data() {
		return ({
			list: [],
			limit: 10,
			page: 1,
			stateId: 0,
			cityId: 0,
			brandId: 0,
			googleMapConfig: {
				zoom: 14,
			},
			center: {
				// Kiev
				lat: 50.450001,
				lng: 30.523333
			},
			mapStatus: false,
			autoSetUpByCoords: false,
			addressByGeolocation: '',
			myCoordinates: {},
			brandCategory: 1,
			newMarker: {},
			sortTypeSTO: 4,
			title: `${this.$t('Мережа сертифікованих AUTO.RIA центрів перевірок')}`,
			description: `${this.$t('СТО-партнери «Перевірених авто»: тільки правда про ваше майбутнє авто')}`,
		});
	},
	components: {
		'pagination': require('../Common/Pagination/index.vue').default,
		'sto-item': require('./STOItem/index.vue').default,
		'reviews-slider': require('./ReviewsSlider/index.vue').default,
		'ask-manager': require('./AskManager/index.vue').default,
		'google-map': require('../Common/GoogleMap/index.vue').default,
		'select-auto': require('../Autotest/SelectAuto/index.vue').default,
	},
	computed: {
		...mapGetters({
			userData_: 'Common/userData',
			citiesInState_: 'shared/locations/citiesInState',
			regions_: 'shared/locations/zonesPlain',
			brands_: 'shared/brands/getByCategory',
		}),
		filteredList() {
			let list = this.list;
			if (Number(this.stateId) && list.length) {
				list = list.filter(({state_id}) => state_id === this.stateId);
			}
			if (Number(this.cityId) && list.length) {
				list = list.filter(({city_id}) => city_id === this.cityId);
			}
			if (Number(this.brandId) && list.length) {
				list = list.filter(({markas = []}) => markas.includes(this.brandId));
			}
			return list;
		},
		count() {
			return this.filteredList.length || 0;
		},
		pages() {
			return Math.ceil(this.count / this.limit);
		},
		items() {
			return this.filteredList.slice((this.page - 1) * this.limit, this.page * this.limit);
		},
		regions() {
			return this.regions_ || [];
		},
		cities() {
			return this.citiesInState_(this.stateId) || [];
		},
		brands() {
			let brandList = Object.values(this.brands_(this.brandCategory) || {});
			brandList = brandList.sort((a, b) => a.name.localeCompare(b.name, 'en'));
			return brandList;
		},
		markersList() {
			let markers = [];
			if (Number(this.stateId)) {
				this.filteredList.forEach(({station_name = 'СТО', geo_x, geo_y}) => {
					if (geo_x && geo_y) {
						let marker = this.markerScheme(geo_x, geo_y, station_name);
						markers.push(marker);
					}
				});
			}
			if (Object.keys(this.myCoordinates).length) {
				markers.push({position: this.myCoordinates, notDelete: true});
			}
			if (Object.keys(this.newMarker).values) {
				markers.push(this.newMarker);
			}
			return markers;
		},
		listWithCoords() {
			return Boolean(Object.keys(this.myCoordinates).length) && Boolean(this.list.length);
		},
		showSortItems() {
			return {
				// eslint-disable-next-line max-len
				byReviews: this.filteredList.some(({published_reviews_count = 0}) => Number(published_reviews_count)),
				byDistance: Boolean(Object.keys(this.myCoordinates).length),
				byGenerator: this.filteredList.some(({exist_generator = 0}) => Number(exist_generator)),
			};
		},
	},
	methods: {
		...mapActions({
			fetchSTOList: 'others/autotest/STOList',
			getCitiesInState: 'shared/locations/citiesInState',
			fetchZones: 'shared/locations/fetchZones',
			getUserData: 'Common/userData',
			fetchByCategory: 'shared/brands/fetchByCategory',
		}),
		onChangePage(page) {
			this.page = page;
		},
		getUserLocation() {
			navigator.geolocation.getCurrentPosition(
				position => {
					const coordinates = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					};
					this.myCoordinates = coordinates;
					this.center = coordinates;
					this.getAddressByCoords(this.center)
						.then(address => {
							if (address) {
								this.addressByGeolocation = address;
								this.regions.forEach(({ name = '', value = 1}) => {
									const stateReg = new RegExp(name, 'i');
									const state = address.match(stateReg);
									if (state) {
										this.stateId = value;
										this.autoSetUpByCoords = true;
									}
								});
							} else {
								this.getPassportLocation();
							}
						})
						.catch(e => {
							this.getPassportLocation();
							console.error('ERROR:::this.getAddressByCoords', e.message);
						});
				},
				error => {
					this.getPassportLocation();
					this.sortList(this.sortTypeSTO);
					console.error('ERROR:::this.getAddressByCoords v2', error.message);
				},
			);
		},
		setMapCenter(coordinates, stationName) {
			this.newMarker = this.markerScheme(coordinates.lat, coordinates.lng, stationName);
			this.center = coordinates;
			const map = this.$refs.googleMap;
			const top = this._result(map, '$el.offsetTop');
			if (Number(top)) {
				scrollToTop(top);
			}
		},
		setMapCenterByAddress(id, array) {
			const item = this[array].find(({value}) => value === id);
			if (item && !this.autoSetUpByCoords) {
				const { name = '' } = item;
				this.$refs.googleMap.setCenterByAddress(name);
			}
		},
		getAddressByCoords(location) {
			return this.$refs.googleMap.getAddressByCoords(location);
		},
		setMapStatus(status) {
			this.mapStatus = status;
		},
		markerScheme(geo_x, geo_y, station_name) {
			return {
				position: {
					lat: geo_x,
					lng: geo_y
				},
				label: {
					text: station_name,
					color: '#414042',
					className: 'google-label'
				},
				icon: {
					url: 'https://cdn.riastatic.com/docs/pictures/common/1/133/13361/13361.png',
				},
				optimized: true,
			};
		},
		getDistanceBetweenCoords(lat1, lon1, lat2, lon2) {
			return getDistance(lat1, lon1, lat2, lon2);
		},
		sortList(type) {
			switch (Number(type)) {
				// по дистанції, спрацьовує коли включена геолокація
				case 1:
					this.list.sort((a, b) => a.distance - b.distance);
					break;
				// по назві міста
				case 2:
					this.list.sort((a, b) => a.station_name.localeCompare(b.station_name, 'ru'));
					break;
				// по к-сті відгуків
				case 3:
					this.list.sort((a, b) => b.published_reviews_count - a.published_reviews_count);
					break;
				// за наявністю генератора
				case 4:
					this.list.sort((a, b) => b.exist_generator - a.exist_generator);
					break;
			}
		},
		getPassportLocation() {
			this.getUserData().then(({ location: { city = {}, state = {} } = {} } = {}) => {
				state.id ? this.stateId = state.id : this.stateId = 10; // Kiev default
				if (city.id) {
					// @todo пофікстити
					setTimeout(() => {
						this.cityId = city.id;
					}, 500);
				}
			}).catch(e => {
				this.stateId = 10; // Kiev default
				console.error('ERROR:::this.getUserData() STO', e.message);
			});
		},
		clearMap() {
			this.$refs.googleMap.deleteAllMarkers();
		}
	},
	watch: {
		stateId(newState) {
			if (Number(newState)) {
				this.clearMap();
				// Не понятно чого Київську обл веде в єбеня
				if (newState === 10) {
					this.center = {
						lat: 50.450001,
						lng: 30.523333
					};
				} else {
					this.setMapCenterByAddress(newState, 'regions');
				}
				this.getCitiesInState({ id: newState });
			}
			this.cityId = 0;
		},
		cityId(newCity) {
			if (Number(newCity) && !this.autoSetUpByCoords) {
				this.setMapCenterByAddress(newCity, 'cities');
			} else {
				this.autoSetUpByCoords = false;
			}
		},
		mapStatus(newStatus) {
			if (newStatus) {
				this.getUserLocation();
			}
		},
		cities(newValue) {
			if (newValue.length && this.autoSetUpByCoords) {
				newValue.forEach(({ name = '', value = 1}) => {
					const stateReg = new RegExp(name, 'i');
					const city = this.addressByGeolocation.match(stateReg);
					if (city) {
						this.cityId = value;
					}
				});
			}
		},
		listWithCoords(newValue) {
			if (newValue) {
				this.list.forEach(sto => {
					sto.distance = this.getDistanceBetweenCoords(
						this.myCoordinates.lat,
						this.myCoordinates.lng,
						sto.geo_x,
						sto.geo_y
					);
					return sto;
				});
				this.sortTypeSTO = 1;
			}
		},
		sortTypeSTO(newType) {
			this.sortList(newType);
		},
		'showSortItems.byReviews'(newVal, oldVal) {
			if (oldVal && !newVal) {
				this.sortTypeSTO = Object.keys(this.myCoordinates).length ? 1 : 2;
			}
		},
	},
	mounted() {
		this.fetchSTOList({
			include: 'markas,published_reviews_count',
			langId: this.langId
		}).then(list => {
			// "package_id": 0 - виключення для СТО "Териториальний сервісний центр"
			const ignoreList = [230, 309];
			// eslint-disable-next-line max-len
			this.list = list.filter(({package_id, station_id, is_active}) => package_id && is_active && !ignoreList.includes(station_id));
		});
		this.fetchZones();
		this.fetchByCategory(this.brandCategory);
	},
	i18n: require('./i18n').default,
};

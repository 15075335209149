export default {
	messages: {
		ru: {
			'центрів': 'центров | центр | центра | центров',
			'Мережа сертифікованих AUTO.RIA центрів перевірок': 'Сеть сертифицированных AUTO.RIA центров проверок',
			'Знайдено': 'Найдено',
			'перевірок': 'проверок',
			'Виберіть область': 'Выберите область',
			'Виберіть місто': 'Выберите город',
			'Вкажіть марку авто': 'Укажите марку авто',
			'Знайти СТО': 'Найти СТО',
			'Сортування': 'Сортировка',
			'З генератором': 'С генератором',
			'За алфавітом': 'По алфавиту',
			'За відгуками': 'По отзывам',
			'За відстанню': 'По расстоянию',
			'СТО-партнери «Перевірених авто»: тільки правда про ваше майбутнє авто': 'СТО-партнеры «Проверенных авто»: только правда о вашем будущем авто',
		},
		uk: {
			'центрів': 'центрів | центр | центри | центрів',
			'Мережа сертифікованих AUTO.RIA центрів перевірок': 'Мережа сертифікованих AUTO.RIA центрів перевірок',
			'Знайдено': 'Знайдено',
			'перевірок': 'перевірок',
			'Виберіть область': 'Виберіть область',
			'Виберіть місто': 'Виберіть місто',
			'Вкажіть марку авто': 'Вкажіть марку авто',
			'Знайти СТО': 'Знайти СТО',
			'Сортування': 'Сортування',
			'З генератором': 'З генератором',
			'За алфавітом': 'За алфавітом',
			'За відгуками': 'За відгуками',
			'За відстанню': 'За відстанню',
			'СТО-партнери «Перевірених авто»: тільки правда про ваше майбутнє авто': 'СТО-партнери «Перевірених авто»: тільки правда про ваше майбутнє авто',
		},
	}
};

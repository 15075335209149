const { mapGetters, mapActions } = require('vuex');

export default {
	name: 'STOItem',
	props: ['data'],
	data() {
		return ({
			isHiddenNumber: false,
			reviewsCount: 0,
			averageRating: 0,
		});
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
		}),
		firstPhone() {
			return this._result(this.data, 'phone_formatted', '').split(',')[0] || '';
		},
		phone() {
			return this.isHiddenNumber ? this.firstPhone : this.firstPhone.replace(/\s.+/g, ' xx-xx-xxx');
		},
		clearPhone() {
			return this.firstPhone.replace(/\D+/g, '') || '';
		},
	},
	methods: {
		...mapActions({
			fetchReviewsAutotest: 'others/autotest/reviewsAutotest',
		}),
		showPhone() {
			this.isHiddenNumber = true;
		},
		getSTOReview() {
			const params = {
				station_id: this.data.station_id
			};
			this.fetchReviewsAutotest(params).then(({total = 0, comments = []}) => {
				if (comments.length) {
					let ratingsArr = [];
					comments.forEach(({recommed}) => ratingsArr.push(recommed));
					this.calcAverageRating(ratingsArr);
				}
				this.reviewsCount = total;
			});
		},
		changeMapCenter() {
			const coordinates = {
				lat: this.data.geo_x,
				lng: this.data.geo_y
			};
			this.$emit('changeMapCenter', coordinates, this.data.station_name);
		},
		calcAverageRating(arr) {
			const ratingTable = {
				1: 5,
				2: 2.5,
			};
			if (arr.length) {
				const ratings = arr.filter(Number);
				if (ratings.length) {
					const count = ratings.length;
					const sum = ratings.reduce((acc, item) => acc + ratingTable[item], 0);
					const average = sum / count;
					this.averageRating = average.toFixed(1);
				}
			}
		}
	},
	mounted() {
		this.getSTOReview();
	},
	i18n: require('./i18n').default,
};

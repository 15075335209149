const { mapActions, mapGetters } = require('vuex');

export default {
	name: 'ReviewsSlider',
	data() {
		return ({
			flickityOptions: {
				cellAlign: 'left',
				freeScroll: true,
				lazyLoad: true,
				pageDots: false,
				contain: true,
			},
			list: [],
			page: 1,
			size: 10,
			count: 0,
			initFlickity: false,
		});
	},
	components: {
		'flickity': require('../../Common/Flickity/Flickity.vue').default,
		'slide': require('../Slide/index.vue').default,
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
		})
	},
	methods: {
		...mapActions({
			fetchReviewsAutotest: 'others/autotest/reviewsAutotest',
		}),
		getReviews(page, size) {
			const params = {
				page,
				size
			};
			this.fetchReviewsAutotest(params).then(reviews => {
				const comments = reviews.comments;
				comments.forEach(comment => {
					this.list.push(comment);
				});
				this.count = reviews.total;
			});
		},
		flickityEvents(event, listener) {
			this.$refs.flickity.on(event, listener);
		},
		appendFlickityElement(append) {
			if (this.initFlickity) {
				this.$refs.flickity.append(append);
			}
		},
		changeSlides() {
			this.initFlickity = true;
			this.flickityEvents('change', (index) => {
				if (index >= this.list.length - 3 && index < this.count) {
					this.page += 1;
					this.getReviews(this.page, this.size);
				}
			});
		},
	},
	mounted() {
		this.getReviews(this.page, this.size);
	},
	i18n: require('./i18n').default,
};

export default {
	messages: {
		ru: {
			'Смотреть все отзывы': 'Смотреть все отзывы',
			'Відгуки по автосервісах': 'Отзывы по автосервисам',
		},
		uk: {
			'Смотреть все отзывы': 'Дивитися всі відгуки',
			'Відгуки по автосервісах': 'Відгуки по автосервісах',
		},
	}
};
